<template>
  <div class="steps flex">
      <div class="left-info">
        <img :src=" stepArr[0].isActive ? require('@/assets/img/common/list_selet.png') : require('@/assets/img/common/list_normal.png')" class="img-icon">
        <div :style="{'color':stepArr[0].isActive ? '#3F7C53' : '#999999'}">{{stepArr[0].name}}</div>
      </div>
      <div class="rounds" :class="{'inactive-round': index> 2 && !stepFull}" v-for="(item,index) in [0,1,2,3,4,5]" :key="index">
      </div>
      <div class="right-info">
        <img :src=" stepArr[1].isActive ? require('@/assets/img/common/list_selet.png') : require('@/assets/img/common/list_normal.png')" class="img-icon">
        <div :style="{'color':stepArr[1].isActive ? '#3F7C53' : '#999999'}">{{stepArr[1].name}}</div>
      </div>
  </div>
</template>

<script>
export default {
  name: "Step",
  props: {
    stepArr: Array,
    stepFull: false
  }
}
</script>

<style scoped lang="less">
.steps {
  background-color: white;
  height: 70px;
  justify-content: space-around;
  font-size: 12px;
  padding-top: 15px;
  .left-info{
    background-color: white;text-align: center;margin-left: 50px
  }
  .img-icon{
    width: 24px;height: 24px
  }
  .rounds{
    width:8px;height: 8px;background-color:#3F7C53;border-radius: 4px;margin-top: 10px
  }
  .inactive-round{
    background: #999;;
  }
  .right-info{
    background-color: white;text-align: center;margin-right: 50px
  }
}
</style>
