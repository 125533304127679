<template>
        <div :id="elID"
             class="village-charts">

        </div>
</template>

<script>
        export default {
                name: "EchartsHorizontalHistogram",
                props:['elID','showTitleArray','showMax','chartArr','barWidth'],
                data () {
                        return {
                                title: '',
                                indicator: [
                                ],
                                surveyScore: '',
                        }
                },
                mounted () {
                        this.drawCharts();
                },
                watch:{
                        chartArr(newVal){
                                console.log("dddd",newVal);
                                this.drawCharts();
                        }
                },
                methods: {
                        toSetShowData(){
                                var tmpIndicator=[];
                                for (var i = 0;i < this.showTitleArray.length;i++){
                                        var tmpDic = {};
                                        tmpDic.text = this.showTitleArray[i];
                                        tmpDic.max = this.showMax;
                                        tmpIndicator.push(tmpDic);
                                }
                                this.indicator = tmpIndicator;
                                this.drawCharts()
                        },
                        drawCharts () {
                                let elementById = document.getElementById(this.elID)
                                let villageCharts = this.$echarts.init(elementById)
                                let option = {
                                        tooltip: {
                                                trigger: 'axis',
                                                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                                                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                                                }
                                        },
                                        // legend: {
                                        //     backgroundColor:'#30BF78',
                                        //     itemWidth:8,
                                        //     itemHeight:8,
                                        //     data: ['站长情况', '家庭情况', '其他信息'],
                                        //     textStyle:{
                                        //         backgroundColor:'#30BF78'
                                        //     }
                                        // },
                                        grid: {
                                                left: '0%',
                                                right: '4%',
                                                bottom: '5%',
                                                containLabel: true
                                        },
                                        xAxis: {
                                                type: 'value',
                                                max:100,
                                                splitLine:{
                                                        show:true,
                                                        lineStyle: {
                                                                type: 'dashed'
                                                        }
                                                },
                                                axisLine:{
                                                        lineStyle: {
                                                                type: 'dashed'
                                                        },
                                                }
                                        },
                                        yAxis: {
                                                type: 'category',
                                                data: [''],
                                                axisLine:{
                                                        lineStyle: {
                                                                type: 'dashed'
                                                        },
                                                }

                                        },
                                        series: [
                                                {
                                                        name: '站长情况',
                                                        color:"#3F7C53",
                                                        type: 'bar',
                                                        stack: '总量',
                                                        label: {
                                                                show: false,
                                                                position: 'insideRight'
                                                        },
                                                        data: this.chartArr[0]
                                                },
                                                {
                                                        name: '家庭情况',
                                                        color:'#F5A623',
                                                        type: 'bar',
                                                        stack: '总量',
                                                        barMinWidth:this.barWidth,
                                                        barMaxWidth:this.barWidth,
                                                        label: {
                                                                show: false,
                                                                position: 'insideRight'
                                                        },
                                                        data: this.chartArr[1]
                                                },
                                                {
                                                        name: '其他信息',
                                                        color:'#F7D470',
                                                        type: 'bar',
                                                        stack: '总量',
                                                        label: {
                                                                show: false,
                                                                position: 'insideRight'
                                                        },
                                                        data: this.chartArr[2],
                                                        itemStyle:{
                                                                barBorderRadius:[0, this.barWidth / 5, this.barWidth / 5, 0],
                                                        }
                                                }
                                        ]
                                }
                                villageCharts.setOption(option)
                        }
                }
        }
</script>

<style scoped>
        .village-charts{
                background-color: white;
        }
</style>
