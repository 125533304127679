
<template>
  <div class="stationAgent-score">
    <step :stepArr="stepArr" :stepFull="true"></step>
    <div class="main-info">
      <div class="title">村长调研分数</div>
      <div class="bottom-card">
        <div class="flex justify_content_space_between align_items_center" >
          <div class="score-title">{{stationName}}</div>
          <!-- <div class="backBtn" @click="goback">返回修改</div> -->
        </div>
        <div class="flex">
          <div class="masterScore">{{masterScore}}</div>
          <div class="masterScore-commnet" >
            <div class="vmasterScore-commnet-info"> {{commnet}} </div>
          </div>
        </div>

        <div class="echartsView">
          <div class="lend">
            <div  class="cell">
              <div class="cell-first-round" style="background-color:#3F7C53"></div>
              <div style="color:#3F7C53;">站长情况</div>
            </div>
            <div  class="cell">
              <div class="cell-first-round" style="background-color:#F5A623"></div>
              <div style="color:#F5A623;">家庭情况</div>
            </div>
            <div  class="cell">
              <div class="cell-first-round" style="background-color:#F7D470 "></div>
              <div style="color:#F7D470">其他信息</div>
            </div>
          </div>
          <div class="flex justify_content_center width_full" >
            <div class="VDS-SSShadowBg score-bg" >
              <EchartsHorizontalHistogram :elID="'echert2'" :chart-arr="[[this.managerScore],[this.familyScore],[this.extraScore]]"
                                          :barWidth="40"
                                          style="width: 80%;height: 160px;"></EchartsHorizontalHistogram>
            </div>
          </div>

        </div>


      </div>
    </div>
    <div class="nextBtn">
      <div class="btn-active"
           @click="nextBtn">完 成</div>
    </div>
  </div>
</template>

<script>
  import Step from '@/components/Step'
  import { Rate } from 'vant';
  import common from "../../utils/common";
  import EchartsHorizontalHistogram from "../ECharts/EchartsHorizontalHistogram";
  import {appAction, uwStatisticAction} from "../../utils/util";
  import {STATION_SCORE_NEXT} from "../../utils/constantNum";
  export default {
    components: {
      Step,
      [Rate.name]: Rate,
      EchartsHorizontalHistogram
    },
    data(){
      return {
        stepArr: [
          {name: "村情信息",isActive: true},
          {name: "站长信息",isActive: true}
        ],
        stationName: '吴房村-姜明明',
        star: 3,
        commnet: '',
        chartArr: [],
        masterScore: null,
        managerScore: '0',
        familyScore: '0',
        extraScore: '0',
      }
    },
    mounted() {
        if(this.$route.query.score) {
        let score = JSON.parse(this.$route.query.score)
        this.stationName = score.stationName + ' -- ' + this.$route.query.masterName
        this.masterScore = score.masterScore
        this.managerScore = score.managerScore // 站长
        this.familyScore = score.familyScore // 家庭
        this.extraScore = score.extraScore // 其他
        this.startShow(this.masterScore)
      }
        uwStatisticAction('/site/stationagentscore','站长评分')
    },
    methods: {
      nextBtn() {
        // let score = JSON.parse(this.$route.query.score)
        this.$store.dispatch('keepAliveState/removeKeepAlive', 'NewSite')
        // this.$router.push({ name: 'mysite',query:{role:common.getItem('userRole')}})
        if (common.getItem('jumpFrom') != undefined) {
          let p = {
            stationNo:this.$route.query.stationNo
          }
          appAction(STATION_SCORE_NEXT,p)
        }else {
          this.$router.push({name:'newsiteList', query: {stationNo:this.$route.query.stationNo}})
        }
        // this.$router.go(-5)
      },
      goback() {

        this.$router.go(-1)
      },
      startShow(score) { 
        if(score > 90){
          this.star = 5
          this.commnet = '卓越'
        } else if(score > 80 ){
          this.star = 4
          this.commnet = '优质'
        } else if(score > 70 ){
          this.star = 3
          this.commnet = '良好'
        } else if(score >= 60 ){
          this.star = 2
          this.commnet = '达标'
        } else if(score < 60){
          this.star = 1
          this.commnet = '未达标'
        }
      },
    }
  }
</script>

<style lang="less" scoped>
  .stationAgent-score{
    font-family:PingFangSC-Regular,PingFang SC;
    height: 100%;
    background: #f5f5f5;
    .main-info{
      margin: 0 0.2rem;
      .title{
        height: 60px;font-size: 17px;color: #333333;font-weight: bold;line-height: 60px;
      }
      .bottom-card{
        height: 336px;background-color: white;border-radius: 5px;
        .score-title{
          padding-top: 20px;padding-left: 20px;font-size: 17px;font-weight: bold;
          width: calc(100% - 110px);
        }
        .backBtn{
          padding-top: 20px;padding-right: 20px;color: #999999;font-size: 14px;text-align: right;width: 100px;
        }
        .masterScore{
          padding-top: 10px;padding-left: 20px;color: #3F7C53;font-size: 45px;font-weight: bold
        }
        .masterScore-commnet{
          margin-top: 40px;padding-right: 20px;
          .vmasterScore-commnet-info{
            color: #3F7C53;background-color: #F1F7F4;;padding:0 5px;height: 20px;line-height: 20px;text-align: center;font-size: 11px;margin-left:10px
          }
        }
        .echartsView{
          width: 100%;height: 200px;;position: relative;margin-top: 10px;
          .lend{
            display: flex;justify-content: center;position: absolute;z-index: 999;width: 100%;margin-top: -10px;
            .cell{
              width: 75px;
              height: 30px;
              display: flex;
              align-items: center;
              border-radius: 20px;
              justify-content: space-around;
              margin-right: 10px;
              font-size: 10px;
              .cell-first-round{
                width: 8px;height: 8px;border-radius: 2px;background-color:#30BF78
              }
            }
          }
          .score-bg{
            border-radius: 20px;width: 100%;height: 220px;display: flex;justify-content: center
          }
        }
      }
    }
    .nextBtn {
  width: 100%;
  padding: 0.24rem;
  background: #f7f8f9;
  position: absolute;
  bottom: 0;
  left: 0;
}
.btn-active {
  height: 0.98rem;
  line-height: 0.98rem;
  text-align: center;
  background: #3f7c53;
  border-radius: 0.08rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  width: 100%;
  opacity: 1;
}
    .VDS-SSShadowBg{
      background: url("../../assets/img/common/scoreShadowBg.png");
      background-size: 100% 100%;
    }
    .VDS-ShadowBg{
      background: url("../../assets/img/common/shadowBg.png");
      background-size: 100% 100%;
    }


  }
</style>
